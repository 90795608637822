import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private auth: AngularFireAuth,
        private router: Router,
    ) { }

    canActivate(router, state): Observable<boolean> {
        return this.auth.authState.pipe(first(), map(authState => {
            if (!authState) {
                this.router.navigate(['/login']);
                return false;
            } else return true;
        }));
    }
}